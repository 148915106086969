import React, { useState } from "react";

// Updated interface for ComboBox props
interface ComboBoxProps {
    options: string[]; // List of options to display in the ComboBox
    value: string; // Selected value
    onChange: (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => void; // Handle change event
    name: string; // Name for the input field (important for form state)
    required?: boolean; // Optional required flag
}

const ComboBox: React.FC<ComboBoxProps> = ({ options, value, onChange, name, required }) => {
    const [newOption, setNewOption] = useState<string>("");

    const handleNewOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewOption(e.target.value);
    };

    const handleAddNewOption = () => {
        if (newOption.trim() && !options.includes(newOption)) {
            onChange({ target: { value: newOption.trim(), name } } as React.ChangeEvent<HTMLSelectElement>);
            setNewOption("");
        }
    };

    return (
        <div className="combo-box">
            <div className="form-group">
                <label htmlFor={name}>Select or Add an Item:</label>
                <select
                    id={name}
                    name={name}
                    value={value} // Bind to the selected value
                    onChange={onChange} // Pass down the change handler
                    required={required}
                >
                    <option value="" disabled>
                        Select an option
                    </option>
                    {options.map((option, index) => (
                        <option key={index} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
            </div>
            <div className="form-group">
                <input
                    type="text"
                    placeholder="Add new item"
                    value={newOption}
                    onChange={handleNewOptionChange}
                />
                <button type="button" onClick={handleAddNewOption}>
                    Add
                </button>
            </div>
        </div>
    );
};

export default ComboBox;
