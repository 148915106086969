import "./index.css";
const About = () => {
return <div className="about-page">

  <h1>About Us</h1>

    <section className="about-content">
      <p>
        Hogcloud provides services in cloud computing, AI integration, website development, and custom
        software/mobile applications.  Modern, tailored solutions are available to help businesses grow and stay competitive.
      </p>

      <p>
        Founded in 2021, and building on over 25 years of experience, Hogcloud's mission is to empower clients with cutting-edge technology and strategic
        guidance, enabling them to achieve their goals. Whether you need to enhance your online presence, streamline
        operations, or leverage AI, the Hog is here to help.
      </p>

      <p>
        Services and partnerships with Hogcloud come with collaboration and customer satisfaction, building long-term relationships and delivering exceptional
        results.
      </p>
      <p>Trust us to be your partner in growth and success.</p>
    </section>
</div>
};

export default About;