import "./index.css"
import logo_hogcloud1 from "../../assets/images/hogcloud-logo1.png";
import logo_hogcloud_forward from "../../assets/images/hogcloud_logo_forward.png";
import React, { useState } from 'react';


const Home = () => {
    const [isAnimating, setIsAnimating] = useState(true);

    const restartAnimation = (e: any) => {
        // Temporarily remove and re-add the class to restart animation
        e.target.classList.remove('animate');
        void e.target.offsetWidth; // Trigger reflow
        e.target.classList.add('animate');
    };
    return <>
              <div className="banner">
                <img
                    src={logo_hogcloud1}
                    alt="Hogcloud Logo"
                    className={`logo ${isAnimating ? 'animate' : ''}`}
                    onClick={restartAnimation}
                />
                <h1 className="banner-text">Hogcloud</h1>
                <img
                    src={logo_hogcloud1}
                    alt="Hogcloud Logo"
                    className={`logo logo-flipped ${isAnimating ? 'animate' : ''}`}
                    onClick={restartAnimation}
                />
            </div>
            <div>
               {/* <img src={logo_hogcloud_forward} alt="Hogcloud Logo facing forward" />  */ }
            </div>
    
    
    </>;
    /*
    return <div>
        <p>dddd</p>
        <p>dddd</p>
        <p>dddd</p>
        <p>dddd</p>
        <p>dddd</p>
        <p>dddd</p>
        <p>dddd</p>
        <p>dddd</p>
        <p>dddd</p>
        <p>dddd</p>
        <p>dddd</p>
        <p>dddd</p>
        <p>dddd</p>
        <p>dddd</p>
        <p>dddd</p>
        <p>eeee</p>

        </div>;*/
/*
    return (
        <>
            <div className="banner">
                <img
                    src={logo_hogcloud1}
                    alt="Hogcloud Logo"
                    className={`logo ${isAnimating ? 'animate' : ''}`}
                    onClick={restartAnimation}
                />
                <h1 className="banner-text">Hogcloud</h1>
                <img
                    src={logo_hogcloud1}
                    alt="Hogcloud Logo"
                    className={`logo logo-flipped ${isAnimating ? 'animate' : ''}`}
                    onClick={restartAnimation}
                />
            </div>
            <section>
            <div className="home_content">
                <img src={logo_hogcloud_forward} alt="Hogcloud Logo facing forward" />   
            </div>
            </section>
        </>
    );
*/
    };


export default Home;
