import React from 'react';
import { NavLink } from 'react-router-dom';
import { RiInformationLine, RiContactsLine, RiAccountBoxFill } from 'react-icons/ri';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import './index.css'
import logo_hogcloud1 from "../../assets/images/hogcloud-logo1.png";


const Navbar = () => {
  return (
    <nav>
      <ul>
        <li>
          <NavLink to="/" className={({isActive}) => isActive ? 'active2 logo-link' : 'inactive logo-link'}>
            <img 
              src={logo_hogcloud1} 
              alt="hogcloud logo pig in cloud" 
              className="hc_logo_small responsive-logo"
            />
          </NavLink>
        </li>
        <li>
            <NavLink to="/services" className={({ isActive }) => isActive ? "active" : ""}>
                    <FontAwesomeIcon icon={faFloppyDisk} className='active hc-nav-icon-spacing' />
                    Services
            </NavLink>
        </li>          
        <li>
          <NavLink to="/about" className={({ isActive }) => isActive ? "active" : ""}>
            <RiInformationLine className='active hc-nav-icon-spacing'/>
            About
          </NavLink>
        </li>        
        <li>
          <NavLink to="/contact" className={({ isActive }) => isActive ? "active" : ""}>
            <RiContactsLine className='active hc-nav-icon-spacing'/>
            Contact
          </NavLink>
        </li>
      </ul>
      
    </nav>
  );
};

export default Navbar;
