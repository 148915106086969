    const Portfolio = () => {
        const handleClick = () => {
            window.location.href = '/narrative-manager';
        };

        return <div>
                <div className='banner'>
                    <div className='content'>
                        {/* <button onClick={handleClick}>Narrative Manager</button> */}                    </div>
                </div>
            </div>;
};

export default Portfolio;
