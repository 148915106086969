import { useEffect, useState } from 'react';
import { Buffer } from 'buffer';
import './index.css'
import r1 from '../../assets/images/r1.png';
import r2 from '../../assets/images/r2.png';


interface IBotData {key:string , image: string};

const Contact = () => {

const post_url = "https://vpnyyhv2cvl66gk4oguyebiimy0lzstt.lambda-url.us-east-1.on.aws/";

const [isSubmitted, setIsSubmitted] = useState(false);
const [formReady, setFormReady] = useState(false);  
const [botChoice, setBotChoice] = useState<string>('');
const [botStatus, setBotStatus] = useState<string>('');
const [botData, setBotData] = useState<IBotData>();

const [formData, setFormData] = useState({
    fullname: '',
    email: '',
    custom_message: '',
  });
const bot_data_Items : IBotData[] = [{
    "key": "truck",
    "image" : r1,
    },
    {
    "key": "cat",
    "image": r2
    }
];

const EXPECTED_API_KEY = "091cb55d-d130-4536-ab37-46a3f91e85dc";
const validateBot  = () => {
    
    let success = false;
    console.log(`Validating .. botchoice is ${botChoice}`);
    if (botChoice.toLowerCase() == botData?.key){
        success = true;
    } 
    else {
        setBotStatus('Incorrect');
    }
    return success;
}
const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {

    const { name, value } = event.target;
    console.log(`Changing name ${name} to value ${value}`);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

const isFormReady = () => {
    let success = false;
    if (formData.fullname && formData.email) {
        success = true;
    }
    console.log(`Form ready ${success}`);
    setFormReady(success);
    return success;
}

useEffect(() => {
    // Parse the query parameter from the URL
    const params = new URLSearchParams(window.location.search);
    const successParam = params.get("success");

    // Update the state based on the parameter
    if (successParam === "true") {
      setIsSubmitted(true);
    }

    const botImages = Object.values(bot_data_Items);
    const randomIndex = Math.floor(Math.random() * botImages.length);
    const randomBotImage = botImages[randomIndex];
    setBotData(randomBotImage);
    console.log(`Setting bot data to ${JSON.stringify(botData)}`);
  }, []); // Runs only once after the component mounts


const handleSubmit = async () => {


   // event.preventDefault();
    if (isFormReady() && validateBot()){
        const x_api_key = Buffer.from(EXPECTED_API_KEY, 'utf-8').toString('base64');

        const form = document.forms.namedItem('contact') as HTMLFormElement | null;
     
        if (form) {
            setBotStatus('Sending ...');
            const formData = new FormData(form);
            //const formObject = Object.fromEntries(formData.entries());

            const formObject: Record<string, any> = {};
            formData.forEach((value, key) => {
                if (key === "requested_services") {
                    if (!formObject[key]) {
                        formObject[key] = [];
                    }
                    formObject[key].push(value);
                } else {
                    formObject[key] = value;
                }
            });
            const formJson = JSON.stringify(formObject);
            console.log(formJson);
            try {
                const response = await fetch(post_url, {
                    method: 'POST',
                    body: formJson,
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': x_api_key
                    }
                });
                const data = await response.json();
                if (response.ok) {
                    setIsSubmitted(true);
                    window.location.href = "/contact?success=true";

                } else {
                    setIsSubmitted(false);
                    setBotStatus('Submit failure');
                }
            } catch (error) {
                console.log(error);
                setIsSubmitted(false);
                setFormReady(false);
                setBotStatus('Submit failure');
            }
        } else {
            setIsSubmitted(false);
            setFormReady(false);
            setBotStatus('Form not found');
        }
    } else {
        setFormReady(false);
    }
};
{{/**  
*/}}
    return (   
        <div>      
            {isSubmitted ? <div className="success-message"><br></br>Message received! We will contact you soon.</div> :
 
        <div className='contact-form'>
            <form name="contact" id="contact">
                <div className="form-row">
                    <div className="form-column">
                        <label htmlFor="name">
                            Your name
                        </label>
                        <input type="text" id="name" name="fullname" defaultValue={formData.fullname} onChange={handleChange} />
                    </div>
                    <div className="form-column">
                        <label htmlFor="email">
                            Email
                        </label>
                        <input type="email" id="email" name="email" defaultValue={formData.email} onChange={handleChange} />
                    </div>
                </div>
                <div className='form-row'>

                </div>
                <div className='form-row'>

                    <label htmlFor="custom_message">
                        Tell us how we can help you
                    </label>
                    <div className="checkbox-group">
                        <div className='form-container'>
                            <div className='two-column-container left'>
                                <div className="checkbox-item" style={{display: 'flex', alignItems: 'center'}}>
                                    <input type="checkbox" id="website" name="requested_services" value="website" />
                                    <label htmlFor="website" style={{marginLeft: '2px'}}>
                                        Website
                                    </label>
                                </div>

                                <div className="checkbox-item" style={{display: 'flex', alignItems: 'center'}}>
                                    <input type="checkbox" id="cloud-consulting" name="requested_services"
                                        value="cloud-consulting" />
                                    <label htmlFor="cloud-consulting" style={{marginLeft: '2px'}}>
                                        Cloud
                                    </label>
                                </div>

                                <div className="checkbox-item" style={{display: 'flex', alignItems: 'center'}}>
                                    <input type="checkbox" id="ai" name="requested_services" value="ai" />
                                    <label htmlFor="ai" style={{marginLeft: '2px'}}>
                                        AI
                                    </label>
                                </div>

                                <div className="checkbox-item" style={{display: 'flex', alignItems: 'center'}}>
                                    <input type="checkbox" id="development" name="requested_services" value="development" />
                                    <label htmlFor="development" style={{marginLeft: '2px'}}>
                                        Development
                                    </label>
                                </div>
                            </div>
                            <div className='two-column-container right'>
                                <div className="checkbox-item" style={{display: 'flex', alignItems: 'center'}}>
                                    <input type="checkbox" id="consulting" name="requested_services" value="consulting" />
                                    <label htmlFor="consulting" style={{marginLeft: '2px'}}>
                                        Consulting
                                    </label>
                                </div>
                                <div className="checkbox-item" style={{display: 'flex', alignItems: 'center'}}>
                                    <input type="checkbox" id="support" name="requested_services" value="support" />
                                    <label htmlFor="support" style={{marginLeft: '2px'}}>
                                        Support
                                    </label>
                                </div>
                                <div className="checkbox-item" style={{display: 'flex', alignItems: 'center'}}>
                                    <input type="checkbox" id="tutoring" name="requested_services" value="tutoring" />
                                    <label htmlFor="tutoring" style={{marginLeft: '2px'}}>
                                        Tutoring
                                    </label>
                                </div>
                                <div className="checkbox-item" style={{display: 'flex', alignItems: 'center'}}>
                                    <input type="checkbox" id="other" name="requested_services" value="other" />
                                    <label htmlFor="other" style={{marginLeft: '2px'}}>
                                        Other
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <textarea id="custom_message" rows={3} name="custom_message" defaultValue={formData.custom_message} onBlur={handleChange} />
                </div>
                <div className='form-row'>
                    <div className='three-column-container'>
                        <div className='column'> 
                            Hi Human, What is this ?
                        </div>
                        <div className='column'>     
                        <img src={botData?.image} className='botimage'/><br></br><br></br>
                            <input
                                    type="text"
                                    id="botchoice"
                                    style={{ width: '5em' }} 
                                    maxLength={5}
                                    defaultValue={botChoice} // Controlled input field
                                    onBlur={(e) => setBotChoice(e.target.value)} // Update state on input change
                                />
                        </div>
                        <div className='column'>
                            <button type="button" onClick={(()=>handleSubmit())} className={formReady ? 'button_enabled' : 'button_disabled'}>
                                    Send
                            </button>
                        </div>
                    </div>
                </div>
                <div className='form-row'>
                <span className='botStatus'>{botStatus}</span>

                </div>
            </form> 
        </div>
        }
    </div>
    
    );
};

export default Contact;