
import './App.css';
import React from 'react';

import { Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Navbar from './components/Navbar'
import Layout from './components/Layout';
import Services from './components/Services'
import Contact from './components/Contact';
import Learn from './components/Learn';
import Portfolio from './components/Portfolio';
import Footer from './components/Footer';
import PromptManager from './components/PromptManager';


function App() {
  return (
   <>
      <Navbar />
      <div>
        <Layout>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/services" element={<Services />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/learn" element={<Learn />} />
              <Route path="/portfolio" element={<Portfolio />} />
              <Route path="/promptmanager" element={<PromptManager />} />
            </Routes>
        </Layout>
      </div>
      <Footer/>
   </>
  );
}

export default App;
