// Simple React web form component
import React, { useState, FormEvent } from 'react';
import ComboBox from '../ComboBox';

interface FormData {
  prompt: string;
  promptTitle: string;
}

const PromptManager: React.FC = () => {

    const initialOptions = ["Prompt 1", "Prompt 2", "Prompt 3"];
  const [formData, setFormData] = useState<FormData>({
    prompt: '',
    promptTitle:''
  });

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleTextInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleComboBoxChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
        ...prevState,
        [name]: value, // Update the correct field in the form data
    }));
};


  return (
    <div>
        <div style={{textAlign: 'center', fontSize: '20px', fontWeight:'bold'}}>
            <h1>Prompt Management</h1>
        </div>                
        <div className="form-container">
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="promptTitle">Select Prompt</label>
                    <ComboBox
                        options={initialOptions}
                        value={formData.promptTitle}
                        onChange={handleComboBoxChange}
                        name="promptTitle" // Ensure this matches the form field name
                        required
                    />
                </div>                
                <div className="form-group">
                    <label htmlFor="prompt">Prompt:</label>
                    <textarea
                    id="messageBox"
                    name="message"
                    value={formData.prompt}
                    onChange={handleTextAreaChange}
                    required
                />
                </div>
                <button type="submit">Save</button>
                <button type="submit">Test</button>
            </form>
            <div className="form-group">
                <label htmlFor="textArea">TextArea:</label>
                <textarea
                    id="textArea"
                    value=""
                    />
            </div>
            </div>
    </div>);
}

export default PromptManager;